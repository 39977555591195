import axios from "axios";

export const ctaEvent = async (id) => {
    if (process.browser) {
        const response = await axios.post("/api/fba/cta", {
            eventName: "cta-click",
            eventUrl: window.location.href,
            eventLabel: id,
            hostname: window.location.hostname
        });

        return response.status >= 200 && response.status < 300;
    }
}

export const pageViewEvent = async (id) => {
    if (process.browser) {
        const response = await axios.post("/api/fba/cta", {
            eventName: "PageView",
            eventUrl: window.location.href,
            eventLabel: id,
            hostname: window.location.hostname
        });

        return response.status >= 200 && response.status < 300;
    }
}

let ip = "";
export const ipEvent = async (id) => {
    if (process.browser) {
        if (ip === "") {
            const response = await axios.post("/api/ip", {});
            if (response.status >= 200 && response.status < 300 && response.data.status) {
                ip = response.data.ip;
                window && window.dataLayer && window.dataLayer.push({
                    'event': 'userIp',
                    'ipAddress': response.data.ip
                });
            }
        }
    }
}